import "./index.scss";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import marc1 from "../../../../assets/images/lifestyle-order/1.jpg";
import marc2 from "../../../../assets/images/lifestyle-order/2.jpg";
import marc3 from "../../../../assets/images/lifestyle-order/3.jpg";
import marc4 from "../../../../assets/images/lifestyle-order/4.jpg";
import marc5 from "../../../../assets/images/lifestyle-order/5.jpg";
import marc6 from "../../../../assets/images/lifestyle-order/6.jpg";
import marc8 from "../../../../assets/images/lifestyle-order/7.jpg";
import marc9 from "../../../../assets/images/lifestyle-order/8.jpg";
import marc10 from "../../../../assets/images/lifestyle-order/9.jpg";
import marc11 from "../../../../assets/images/lifestyle-order/10.jpg";
import marc12 from "../../../../assets/images/lifestyle-order/11.jpg";
import marc13 from "../../../../assets/images/lifestyle-order/12.jpg";
import marc14 from "../../../../assets/images/lifestyle-order/13.jpg";
import marc15 from "../../../../assets/images/lifestyle-order/14.jpg";
import marc16 from "../../../../assets/images/lifestyle-order/15.jpg";
import marc17 from "../../../../assets/images/lifestyle-order/16.jpg";
import marc18 from "../../../../assets/images/lifestyle-order/17.jpg";
import marc19 from "../../../../assets/images/lifestyle-order/18.jpg";
import marc20 from "../../../../assets/images/lifestyle-order/19.jpg";
import marc23 from "../../../../assets/images/lifestyle-order/20.jpg";
import marc24 from "../../../../assets/images/lifestyle-order/21.jpg";
import marc25 from "../../../../assets/images/lifestyle-order/22.jpg";
import marc26 from "../../../../assets/images/lifestyle-order/23.jpg";
import marc27 from "../../../../assets/images/lifestyle-order/24.jpg";
import marc28 from "../../../../assets/images/lifestyle-order/25.jpg";
import marc29 from "../../../../assets/images/lifestyle-order/26.jpg";
import marc30 from "../../../../assets/images/lifestyle-order/27.jpg";
import marc31 from "../../../../assets/images/lifestyle-order/28.jpg";
import marc32 from "../../../../assets/images/lifestyle-order/29.jpg";
import marc35 from "../../../../assets/images/lifestyle-order/30.jpg";
import marc36 from "../../../../assets/images/lifestyle-order/31.jpg";
import marc37 from "../../../../assets/images/lifestyle-order/32.jpg";
import marc38 from "../../../../assets/images/lifestyle-order/33.jpg";
import marc40 from "../../../../assets/images/lifestyle-order/34.jpg";
import marc41 from "../../../../assets/images/lifestyle-order/35.jpg";
import marc42 from "../../../../assets/images/lifestyle-order/36.jpg";
import marc43 from "../../../../assets/images/lifestyle-order/37.jpg";
import marc44 from "../../../../assets/images/lifestyle-order/38.jpg";
import marc45 from "../../../../assets/images/lifestyle-order/39.jpg";
import marc46 from "../../../../assets/images/lifestyle-order/40.jpg";
import marc47 from "../../../../assets/images/lifestyle-order/41.jpg";
import marc49 from "../../../../assets/images/lifestyle-order/42.jpg";
import marc50 from "../../../../assets/images/lifestyle-order/43.jpg";
import marc51 from "../../../../assets/images/lifestyle-order/44.jpg";
import marc52 from "../../../../assets/images/lifestyle-order/45.jpg";
import marc53 from "../../../../assets/images/lifestyle-order/46.jpg";
import marc54 from "../../../../assets/images/lifestyle-order/47.jpg";
import marc55 from "../../../../assets/images/lifestyle-order/48.jpg";
import marc56 from "../../../../assets/images/lifestyle-order/49.jpg";
import marc59 from "../../../../assets/images/lifestyle-order/50.jpg";
import marc60 from "../../../../assets/images/lifestyle-order/51.jpg";
import marc61 from "../../../../assets/images/lifestyle-order/52.jpg";
import marc62 from "../../../../assets/images/lifestyle-order/53.jpg";
import marc63 from "../../../../assets/images/lifestyle-order/54.jpg";
import marc64 from "../../../../assets/images/lifestyle-order/55.jpg";
import marc65 from "../../../../assets/images/lifestyle-order/56.jpg";
import marc66 from "../../../../assets/images/lifestyle-order/57.jpg";
import marc67 from "../../../../assets/images/lifestyle-order/58.jpg";
import marc68 from "../../../../assets/images/lifestyle-order/59.jpg";
import marc69 from "../../../../assets/images/lifestyle-order/60.jpg";
import marc70 from "../../../../assets/images/lifestyle-order/61.jpg";
import marc71 from "../../../../assets/images/lifestyle-order/62.jpg";
import marc72 from "../../../../assets/images/lifestyle-order/63.jpg";
import marc73 from "../../../../assets/images/lifestyle-order/64.jpg";

const Lifestyle = () => {
  const navigate = useNavigate();
  const [imagesReady, setImagesReady] = useState(false);

  const clickHandler = () => {
    navigate("/work", { replace: true });
  };

  useEffect(() => {
    window.scrollTo(0, 0);

    // Set a timeout to mark images as ready after the delay
    const timeoutId = setTimeout(() => {
      setImagesReady(true);
    }, []);

    // Clear the timeout to avoid memory leaks
    return () => {
      clearTimeout(timeoutId);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); // The empty dependency array ensures that the effect runs only once after mount

  const handleImageLoad = (event) => {
    event.target.classList.add("loaded"); // Add the 'loaded' class to trigger the fade-in effect
  };

  return (
    <>
      <div className={`lifestyle-container${imagesReady ? " fade-in" : ""}`}>
        <div className="lifestyle-image-container" onLoad={handleImageLoad}>
          <img
            className="landscape"
            src={marc1}
            alt="marc"
            onLoad={handleImageLoad}
            loading="eager"
          />
          <img
            className="landscape-yellow-car"
            src={marc2}
            alt="marc"
            onLoad={handleImageLoad}
            loading="eager"
          />
          <img
            className="portrait"
            src={marc3}
            alt="marc"
            onLoad={handleImageLoad}
            loading="eager"
          />
          <img
            className="landscape"
            src={marc4}
            alt="marc"
            onLoad={handleImageLoad}
            loading="eager"
          />
          <img
            className="lifestyle-landscape-end"
            src={marc5}
            alt="marc"
            onLoad={handleImageLoad}
            loading="eager"
          />
          <img
            className="portrait-start"
            src={marc6}
            alt="marc"
            onLoad={handleImageLoad}
            loading="eager"
          />
          <img
            className="lifestyle-landscape-fourth"
            src={marc8}
            alt="marc"
            onLoad={handleImageLoad}
          />
          <img
            className="landscape"
            src={marc9}
            alt="marc"
            onLoad={handleImageLoad}
            loading="eager"
          />
          <img
            className="portrait-start"
            src={marc10}
            alt="marc"
            onLoad={handleImageLoad}
            loading="eager"
          />
          <img
            className="landscape"
            src={marc11}
            alt="marc"
            onLoad={handleImageLoad}
            loading="eager"
          />
          <img
            className="landscape-7"
            src={marc12}
            alt="marc"
            onLoad={handleImageLoad}
            loading="eager"
          />
          <img
            className="landscape"
            src={marc13}
            alt="marc"
            onLoad={handleImageLoad}
          />
          <img
            className="landscape-9"
            src={marc14}
            alt="marc"
            onLoad={handleImageLoad}
          />
          <img
            className="landscape-8"
            src={marc15}
            alt="marc"
            onLoad={handleImageLoad}
          />
          <img
            className="lifestyle-landscape-second"
            src={marc16}
            alt="marc"
            onLoad={handleImageLoad}
          />
          <img
            className="landscape"
            src={marc17}
            alt="marc"
            onLoad={handleImageLoad}
          />
          <img
            className="landscape-7"
            src={marc18}
            alt="marc"
            onLoad={handleImageLoad}
          />
          <img
            className="landscape-8"
            src={marc19}
            alt="marc"
            onLoad={handleImageLoad}
          />
          <img
            className="lifestyle-landscape-third"
            src={marc20}
            alt="marc"
            onLoad={handleImageLoad}
          />
          <img
            className="lifestyle-landscape-second"
            src={marc23}
            alt="marc"
            onLoad={handleImageLoad}
          />
          <img
            className="lifestyle-landscape-fourth"
            src={marc24}
            alt="marc"
            onLoad={handleImageLoad}
          />
          <img
            className="lifestyle-portrait"
            src={marc25}
            alt="marc"
            onLoad={handleImageLoad}
          />
          <img
            className="lifestyle-landscape-fifth"
            src={marc26}
            alt="marc"
            onLoad={handleImageLoad}
          />
          <img
            className="lifestyle-portrait"
            src={marc27}
            alt="marc"
            onLoad={handleImageLoad}
          />
          <img
            className="portrait-start"
            src={marc28}
            alt="marc"
            onLoad={handleImageLoad}
          />
          <img
            className="landscape"
            src={marc29}
            alt="marc"
            onLoad={handleImageLoad}
          />
          <img
            className="landscape-9"
            src={marc30}
            alt="marc"
            onLoad={handleImageLoad}
          />
          <img
            className="landscape"
            src={marc31}
            alt="marc"
            onLoad={handleImageLoad}
          />
          <img
            className="lifestyle-landscape-fifth"
            src={marc32}
            alt="marc"
            onLoad={handleImageLoad}
          />
          <img
            className="portrait-fire-2"
            src={marc36}
            alt="marc"
            onLoad={handleImageLoad}
          />
          <img
            className="landscape"
            src={marc35}
            alt="marc"
            onLoad={handleImageLoad}
          />
          <img
            className="landscape-8"
            src={marc37}
            alt="marc"
            onLoad={handleImageLoad}
          />
          <img
            className="landscape"
            src={marc38}
            alt="marc"
            onLoad={handleImageLoad}
          />
          <img
            className="lifestyle-portrait"
            src={marc40}
            alt="marc"
            onLoad={handleImageLoad}
          />
          <img
            className="portrait-start"
            src={marc60}
            alt="marc"
            onLoad={handleImageLoad}
          />
          <img
            className="landscape-8"
            src={marc41}
            alt="marc"
            onLoad={handleImageLoad}
          />
          <img
            className="lifestyle-landscape-end"
            src={marc42}
            alt="marc"
            onLoad={handleImageLoad}
          />
          <img
            className="landscape-8"
            src={marc43}
            alt="marc"
            onLoad={handleImageLoad}
          />
          <img
            className="lifestyle-portrait"
            src={marc44}
            alt="marc"
            onLoad={handleImageLoad}
          />
          <img
            className="lifestyle-landscape-fourth-end"
            src={marc45}
            alt="marc"
            onLoad={handleImageLoad}
          />
          <img
            className="landscape-9"
            src={marc46}
            alt="marc"
            onLoad={handleImageLoad}
          />
          <img
            className="landscape-wide"
            src={marc47}
            alt="marc"
            onLoad={handleImageLoad}
          />
          <img
            className="portrait-start"
            src={marc55}
            alt="marc"
            onLoad={handleImageLoad}
          />
          <img
            className="landscape"
            src={marc56}
            alt="marc"
            onLoad={handleImageLoad}
          />
          <img
            className="portrait-start"
            src={marc49}
            alt="marc"
            onLoad={handleImageLoad}
          />
          <img
            className="landscape-9"
            src={marc50}
            alt="marc"
            onLoad={handleImageLoad}
          />
          <img
            className="landscape-7"
            src={marc51}
            alt="marc"
            onLoad={handleImageLoad}
          />
          <img
            className="lifestyle-landscape-second"
            src={marc52}
            alt="marc"
            onLoad={handleImageLoad}
          />
          <img
            className="lifestyle-landscape-sixth"
            src={marc53}
            alt="marc"
            onLoad={handleImageLoad}
          />
          <img
            className="landscape"
            src={marc54}
            alt="marc"
            onLoad={handleImageLoad}
          />
          <img
            className="lifestyle-landscape-second"
            src={marc59}
            alt="marc"
            onLoad={handleImageLoad}
          />
          <img
            className="landscape-7"
            src={marc61}
            alt="marc"
            onLoad={handleImageLoad}
          />
          <img
            className="landscape-8"
            src={marc62}
            alt="marc"
            onLoad={handleImageLoad}
          />
          <img
            className="lifestyle-portrait"
            src={marc63}
            alt="marc"
            onLoad={handleImageLoad}
          />
          <img
            className="portrait-start"
            src={marc64}
            alt="marc"
            onLoad={handleImageLoad}
          />
          <img
            className="lifestyle-portrait"
            src={marc65}
            alt="marc"
            onLoad={handleImageLoad}
          />
          <img
            className="lifestyle-landscape-second"
            src={marc66}
            alt="marc"
            onLoad={handleImageLoad}
          />
          <img
            className="lifestyle-landscape-sixth"
            src={marc67}
            alt="marc"
            onLoad={handleImageLoad}
          />
          <img
            className="portrait-start"
            src={marc68}
            alt="marc"
            onLoad={handleImageLoad}
          />
          <img
            className="lifestyle-landscape-sixth"
            src={marc69}
            alt="marc"
            onLoad={handleImageLoad}
          />
          <img
            className="landscape-7"
            src={marc70}
            alt="marc"
            onLoad={handleImageLoad}
          />
          <img
            className="landscape-8"
            src={marc71}
            alt="marc"
            onLoad={handleImageLoad}
          />
          <img
            className="landscape-9"
            src={marc72}
            alt="marc"
            onLoad={handleImageLoad}
          />
          <img
            className="landscape-wide"
            src={marc73}
            alt="marc"
            onLoad={handleImageLoad}
          />
        </div>
        <div className="button-bottom">
          <button onClick={clickHandler}>{"<"}</button>
        </div>
      </div>
    </>
  );
};

export default Lifestyle;
